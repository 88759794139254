/* global
  autosize
  gettext
  iNoBounce
  LANGUAGE
  Modernizr
  moment
  Sentry
  tinyMCE
*/

// #############################################################################
// HELPERS

const $x = {};

$x.ajax = {
  $defaults: {
    dataType: "text",
    data: {},
    responseType: undefined,
    beforeSend: ($xhr) => {
    },
    error: ($xhr) => {
      console.log("ERROR:", $xhr, $xhr.status, $xhr.statusText);
    },
    success: ($data, $xhr) => {
    },
  },

  _getData ($settings) {
    if ($settings.data instanceof FormData) {
      return $settings.data;
    }

    const $data = new FormData();

    for (const key in $settings.data) {
      $data.append(key, $settings.data[key]);
    }

    return $data;
  },

  fetch (method, url, $settings) {
    const _this = this;
    const $xhr = new XMLHttpRequest();

    $settings = { ..._this.$defaults, ...$settings };

    $xhr.open(method, url);
    $settings.beforeSend($xhr);

    $xhr.onreadystatechange = () => {
      if ($xhr.readyState === XMLHttpRequest.DONE) {
        const status = $xhr.status;

        if (status === 0 || (status >= 200 && status < 400)) {
          // The request has been completed successfully

          if ($settings.success) {
            if ($settings.dataType === "json") {
              $settings.success(JSON.parse($xhr.responseText), $xhr);
            } else {
              $settings.success($xhr.responseText, $xhr);
            }
          }
        } else {
          $settings.error($xhr);
        }
      }
    };

    // The header X-Requested-With allows server side frameworks, such as Django,
    // to identify Ajax requests. It's optional, but can be useful.
    $xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

    if ($settings.responseType) {
      $xhr.responseType = $settings.responseType;
    }

    $xhr.send(_this._getData($settings));

    return $xhr;
  },

  get (url, $settings = {}) {
    const _this = this;

    _this.fetch("GET", url, $settings);
  },

  post (url, $settings = {}) {
    const _this = this;

    _this.fetch("POST", url, $settings);
  },
};

$x.delegateEvent = {
  on ($element, events, selector, handler) {
    $element.addEventListener(events, function (e) {
      const $elements = document.querySelectorAll(selector);


      $elements.forEach(function ($element) {
        if ($element.contains(e.target)) {
          return handler.apply($element, [e]);
        }
      });
    }, true);
  },
};

/**
 * $x.formatFileSize() outputs human readable file size.
 *
 * Args:
 *  bytes (int): Bytes
 *  decimal_point (int): Decimal point
 *
 * Returns:
 *  str: A human readable string with unit.
 *
 **/

$x.formatFileSize = function (bytes, decimal_point) {
  if (bytes === 0) {
    return "0 Bytes";
  }

  const k = 1000;
  const dm = decimal_point || 2;
  const sizes = ["Bytes", "kb", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

// #############################################################################
// GLOBAL VARS

const $window = $(window);
const $body = $("body");
const $html = $("html");

// #############################################################################
// SENTRY

if (document.body) {
  const dsn = document.body.dataset.sentryDsn;

  if (dsn !== "None") {
    Sentry.init({
      dsn: dsn,
      environment: document.body.dataset.sentryEnv,
      release: document.body.dataset.version,
    });
  }
}

// #############################################################################
// BETTER FOCUS

$x.BetterFocus = class {
  constructor ($options) {
    const $defaults = {
      selector: "a, [tabindex]",
    };

    this._$settings = { ...$defaults, ...$options };

    this._focusMethod = false;
    this._lastFocusMethod = false;
  }

  init () {
    const _this = this;

    $x.delegateEvent.on(document, "focus", "a, [tabindex]", function (e) {
      if (!_this._focusMethod) {
        _this._focusMethod = _this._lastFocusMethod;
      }

      const $elements = document.querySelectorAll(_this._$settings.selector);

      $elements.forEach(function ($element) {
        $element.dataset.focusMethod = _this._focusMethod;
      });

      _this._lastFocusMethod = _this._focusMethod;
      _this._focusMethod = false;
    });

    $x.delegateEvent.on(document, "blur", "a, [tabindex]", function () {
      const $elements = document.querySelectorAll(_this._$settings.selector);

      $elements.forEach(function ($element) {
        $element.removeAttribute("data-focus-method");
      });
    });

    $x.delegateEvent.on(document, "keydown", "a, [tabindex]", function () {
      _this._focusMethod = "key";
    });

    $x.delegateEvent.on(document, "mousedown", "a, [tabindex]", function () {
      if (_this._focusMethod === "touch") {
        return;
      }

      _this._focusMethod = "mouse";
    });

    $x.delegateEvent.on(document, "touchstart", "a, [tabindex]", function () {
      _this._focusMethod = "touch";
    });

    window.addEventListener("blur", function () {
      _this._focusMethod = false;
    });
  }
};

const $betterFocus = new $x.BetterFocus();
$betterFocus.init();

// #############################################################################
// AJAX SETUP

function csrfSafeMethod (method) {
  // these HTTP methods do not require CSRF protection
  return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
}

$.ajaxSetup({
  cache: false,
  beforeSend: function (xhr, settings) {
    if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
      xhr.setRequestHeader(
        "X-CSRFToken", $("[name=csrfmiddlewaretoken]").val()
      );
    }
  },
  error: function (e) {
    console.log("ERROR:", e, e.status, e.statusText);
  },
});

// #############################################################################
// JQUERY PLUGIN HELPER

function initPlugin (Plugin, plugin_name) {
  return function ($options) {
    const $args = Array.prototype.slice.call(arguments, 1);

    if ($options === undefined || typeof $options === "object") {
      return this.each(function () {
        if (!$.data(this, "plugin_" + plugin_name)) {
          $.data(this, "plugin_" + plugin_name, new Plugin(this, $options));
        }
      });
    } else if (typeof $options === "string") {
      this.each(function () {
        const $instance = $.data(this, "plugin_" + plugin_name);

        if ($instance && typeof $instance[$options] === "function") {
          $instance[$options].apply($instance, $args);
        } else {
          throw new Error("Method " + $options + " does not exist on jQuery." + plugin_name);
        }
      });
    }
  };
}

// #############################################################################
// IOS

Modernizr.addTest("ios", function () {
  return navigator.userAgent.match(/(iPad|iPhone)/g);
});

iNoBounce.disable();

// #############################################################################
// TINY MCE

function initTinyMCE () {
  function changeTinyMCE (editor) {
    $("").val(editor.getContent());
  }

  tinyMCE.remove();

  tinyMCE.init({
    branding: false,
    content_css: "/static/css/tinymce.min.css",
    editor_selector: "html-textarea",
    contextmenu: false,
    browser_spellcheck: true,
    height: 250,
    inline_styles: false,
    language: "de",
    menubar: false,
    nowrap: false,
    mode: "specific_textareas",
    plugins: "autolink link lists spellchecker wordcount",
    toolbar: "bold | underline | bullist | undo redo cut copy paste pastetext | removeformat | fontsizeselect",
    valid_elements: "a,p,strong,li,ul,u",
    formats: {
      underline: { inline: "u", exact: true },
    },
    // valid_styles: "+a[id|rel|",
    setup: function ($editor) {
      const $textarea = $("#" + $editor.id);

      if ($textarea.attr("disabled")) {
        $editor.settings.readonly = true;
      }

      $editor.on("keyup", function () {
        changeTinyMCE($editor);
      });
      $editor.on("change", function () {
        changeTinyMCE($editor);
      });
    },
  });
}

initTinyMCE();

// #############################################################################
// FANCY BOX

function initFancyBox () {
  const $fancybox = $("[data-fancybox]");

  if ($fancybox.length === 0) {
    return;
  }

  $fancybox.fancybox({
    buttons: [
      "download",
      "fullScreen",
      "close",
    ],
    protect: true,
    afterLoad: function (instance, current) {
      const pixel_ratio = window.devicePixelRatio || 1;

      if (pixel_ratio > 1.5) {
        current.width = current.width / 2;
        current.height = current.height / 2;
      }
    },
  });
}

initFancyBox();

// #############################################################################
// DIALOG

function initDialog () {
  const $dialog_wrapper = $("[data-dialog-wrapper]");
  const KEYCODE_TAB = 9;

  $dialog_wrapper.on("transitionend", function (event) {
    if (event.target !== this || !$dialog_wrapper.hasClass("show")) {
      return;
    }

    const $tab_items = $("a:not([tabindex=\"-1\"]), :input:not([type=\"hidden\"])", "[data-dialog-wrapper]");
    const $first_tab_item = $tab_items.first();
    const $last_tab_item = $tab_items.last();

    $first_tab_item.focus();

    $tab_items.on("keydown", function (event) {
      const is_tab_pressed = (event.key === "Tab" || event.keyCode === KEYCODE_TAB);

      if (!is_tab_pressed) {
        return;
      }

      if (event.shiftKey) {
        if (document.activeElement === $tab_items[0]) {
          event.preventDefault();
          $last_tab_item.focus();
        }
      } else {
        if (document.activeElement === $tab_items[$tab_items.length - 1]) {
          event.preventDefault();
          $first_tab_item.focus();
        }
      }
    });
  });

  $body.on("click", "[data-dialog]", function (event) {
    event.preventDefault();
    $dialog_wrapper.empty();

    $.ajax({
      url: $(this).attr("href"),
      success: function (data) {
        $dialog_wrapper.html(data);

        initCopyAct();

        initFormToggleRelationship();

        setTimeout(function () {
          $dialog_wrapper.addClass("show");
          $html.addClass("no-scroll");

          if (Modernizr.ios) {
            iNoBounce.enable();
          }
        }, 50);
      },
    });
  });

  $body.on("click", "[data-dialog-close]", function (event) {
    event.preventDefault();

    $dialog_wrapper.removeClass("show");
    $dialog_wrapper.empty();

    $html.removeClass("no-scroll");

    if (Modernizr.ios) {
      iNoBounce.disable();
    }
  });

  $dialog_wrapper.on("transitionend", function (event) {
    if (this !== event.target) {
      return;
    }

    if ($dialog_wrapper.hasClass("show")) {
      $("[data-dialog-close=\"focus\"]").focus();
    }
  });

  $(document).on("keyup", function (event) {
    if ($dialog_wrapper.hasClass("show")) {
      if (event.key === "Escape") {
        $("[data-dialog-close]").click();
      }
    }
  });
}

initDialog();

// #############################################################################
// FORM: TEXTAREA

autosize($(".field-textarea").not(".prevent-autoresize"));

// #############################################################################
// FORM: MULTIPLE FILES

function initFormMultipleFiles () {
  const $multiple_files = $("[data-file-multiple]");

  $("input[type=\"file\"]", $multiple_files).on("change", function (event) {
    const $multiple_file = $multiple_files.eq($multiple_files.index(this));
    const $files = event.originalEvent.target.files;
    const $output = $("output", $multiple_file);
    let output_html = "<ul>";

    for (let i = 0; i < $files.length; i++) {
      output_html += "<li><strong class=\"file-name\">" + $files[i].name + "</strong> <span class=\"file-size\">" + $x.formatFileSize($files[i].size) + "</span></li>";
    }

    output_html += "</ul>";

    if (!$files.length) {
      output_html = "";
    }

    $output.html(output_html);
  });
}

initFormMultipleFiles();

// #############################################################################
// FORM VALIDATION

let submitWarningIgnored = 0;
let submitWarning = 0;

function initFormValidation () {
  // Show toast messages

  const toast_submit = sessionStorage.getItem("toast_submit");
  const toast_message = sessionStorage.getItem("toast_message");

  if (toast_submit && toast_message) {
    $.toast({
      hideAfter: 4000,
      icon: toast_submit,
      stack: 3,
      text: toast_message,
    });

    sessionStorage.removeItem("toast_submit");
    sessionStorage.removeItem("toast_message");
  }

  // Insert error message

  function insertErrorMessage ($input, errors) {
    const input_name = $input.attr("name");
    const $warning = $("#id_" + input_name + "_warning");
    let $error = $("#id_" + input_name + "_error");

    if ($input.is(":checkbox") || $input.is(":radio")) {
      $error = $("#id_" + input_name + "_0_error");
    }

    $input.removeClass("invalid");
    $warning.removeClass("show");
    $error.empty().removeClass("show");

    if (errors) {
      if (errors[input_name]) {
        $input.addClass("invalid");
        $warning.addClass("show");

        $error.text(errors[input_name].join(" ")).addClass("show");
      }
    }
  }

  // Validate input

  function checkInputValidity ($input) {
    if ($input[0].checkValidity()) {
      const input_name = $input.attr("name");
      const $warning = $("#id_" + input_name + "_warning");
      let $error = $("#id_" + input_name + "_error");

      if ($input.is(":radio")) {
        $input = $("[name=\"" + input_name + "\"]");
        $error = $("#id_" + input_name + "_0_error");
      }

      $input.removeClass("invalid");
      $warning.removeClass("show");
      $error.empty().removeClass("show");

      return true;
    }

    return false;
  }

  function validateInput ($input) {
    const $form = $input.parents("[data-form]");
    const $formData = new FormData($form[0]);

    // remove files from validation (just validate files on submit)
    $form[0].querySelectorAll("input[type='file']").forEach($element => {
      $formData.delete($element.name);
    });

    $.ajax({
      contentType: false,
      data: $formData,
      enctype: "multipart/form-data",
      processData: false,
      type: "POST",
      url: $form.attr("action"),
      success: function (data) {
        // don't show file-errors (only on submit)
        if ($input.attr("type") !== "file") {
          const remove_errors = [];

          Object.entries(data.errors).forEach(name => {
            if ($("[name='" + name + "']").attr("type") === "file") {
              remove_errors.append(name);
            }
          });
          remove_errors.forEach(name => {
            data.errors.delete(name);
          });

          insertErrorMessage($input, data.errors);
        }
      },
    });
  }

  // Validate before submit

  let inputTimeout;

  $body.on("blur input", "[data-form] :input:visible:not(button)", function () {
    const $input = $(this);

    if ($input.is("select")) {
      validateInput($input);
    } else {
      clearTimeout(inputTimeout);

      inputTimeout = setTimeout(function () {
        validateInput($input);
      }, 750);
    }
  });

  // Validate after submit

  $body.on("submit", "[data-form]", function (event) {
    event.preventDefault();

    const $form = $(this);
    const $inputs = $(":input:visible:not(button)", $form);
    const $button = $("button", $form);
    const $spinner = $(".loading-spinner", $form);
    let timeout;

    function formSuccessfulSubmitted () {
      clearTimeout(timeout);

      if ($button.hasClass("success")) {
        return;
      }

      $button.addClass("success");
      $(".submit", $button).addClass("hidden");
      $(".success", $button).removeClass("hidden");

      timeout = setTimeout(function () {
        $button.removeClass("success");
        $(".submit", $button).removeClass("hidden");
        $(".success", $button).addClass("hidden");
      }, 3000);
    }

    if (!$form[0].checkValidity()) {
      $form.find("[required]:invalid").first().focus();

      $inputs.each(function (index) {
        checkInputValidity($inputs.eq(index));
      });
    }

    const check_change_warnings = checkChangeWarnings($form, $inputs);

    if (check_change_warnings) {
      return false;
    }

    const $form_data = new FormData($form[0]);

    $form_data.append("submit", 1);
    $form_data.append("submit-warning-ignored", submitWarningIgnored);

    if (event.originalEvent) {
      const $clicked_button = $(event.originalEvent.submitter);
      $form_data.append("button_name", $clicked_button.attr("name"));
    }

    $.ajax({
      contentType: false,
      dataType: "json",
      data: $form_data,
      enctype: "multipart/form-data",
      processData: false,
      type: "POST",
      url: $form.attr("action"),
      success: function ($data) {
        if ($data.submit === "success" || $data.submit === "warning") {
          resetChangeWarnings($form);

          if ($data.dialog) {
            $("[data-dialog-close]").click();
          }

          if ($data.updates) {
            $data.updates.forEach(function (item) {
              $(item.wrapper).html(item.content);
              autosize($(".field-textarea").not(".prevent-autoresize"));
            });

            fixSubNav();
            initFormRelationship();
            initDeleteHoliday();

            // reinitialize eventlisteners for date-calculations
            TrialApprenticeshipDurationCalc();
            PractiseDurationCalc();
            if ($("[data-is-miteinander]").length > 0) {
              disableCareResult();
              changeCareResulOnCareGoalChange();
            }

            // dynamically hide fields on save-reload
            toggleBirthday13thMonth();
          }

          if ($data.datatable && window.datatable) {
            window.datatable.ajax.reload(null, false);
          }

          if ($data.redirect) {
            sessionStorage.setItem("toast_message", $data.message);
            sessionStorage.setItem("toast_submit", $data.submit);

            window.location.href = $data.redirect;
          } else if ($data.download) {
            // window.location.href = $data.download;
            window.open($data.download, "_blank");
          } else {
            formSuccessfulSubmitted();
          }
        } else {
          if ($data.errors) {
            displayedDuplicates = false;
          }
          $inputs.each(function (index) {
            insertErrorMessage($inputs.eq(index), $data.errors);
          });

          if ($data.updates) {
            $data.updates.forEach(function (item) {
              $(item.wrapper).html(item.content);
            });

            if (document.querySelector("[data-ssn-exists]")) {
              document.querySelector(".form-footer").setAttribute("hidden", "hidden");
            }

            fixSubNav();
            initFormRelationship();
            initDeleteHoliday();
          }

          if ($data.redirect) {
            sessionStorage.setItem("toast_message", $data.message);
            sessionStorage.setItem("toast_submit", $data.submit);
            window.location.href = $data.redirect;
          }
        }

        const $non_field_errors = $(".non-field-errors", $form);
        const non_field_errors = $data.non_field_errors;

        if ($non_field_errors) {
          if (typeof $data.non_field_errors === "string") {
            $non_field_errors.prop("hidden", true);

            if (non_field_errors) {
              $non_field_errors.prop("hidden", false);
              $non_field_errors.html(non_field_errors);
            }
          } else {
            for (const formErrors in non_field_errors) {
              let errorDivName = "";
              if (formErrors === "__all__") {
                errorDivName = "form-__all__";
              } else {
                errorDivName = formErrors;
              }
              const $errorDiv = $("#id_" + errorDivName + "-error");

              $errorDiv.prop("hidden", true);

              if (non_field_errors[formErrors].length > 0) {
                $errorDiv.prop("hidden", false);
                $errorDiv.html(non_field_errors[formErrors]);
              }
            }
          }
        }

        if (!$data.redirect && $data.message) {
          $.toast({
            hideAfter: 3000,
            icon: $data.submit,
            stack: 3,
            text: $data.message,
          });
        }

        // only reactivate submit button if no redirect
        if (!$data.redirect) {
          // hide spinner / enable button

          $button.removeClass("btn-loader");
          $button.removeAttr("disabled");
          $spinner.addClass("hidden");
        }
        const $addDuplicatesList = document.getElementsByClassName("act-duplicates-list");
        if ($addDuplicatesList.length !== 0) {
          $inputs.prop("readonly", true);
        }
      },
      error: ($xhr) => {
        console.log("ERROR:", $xhr, $xhr.status, $xhr.statusText);
        $button.removeClass("btn-loader");
        $button.removeAttr("disabled");
        $spinner.addClass("hidden");
      },
    });

    // show spinner / disable button

    $button.attr("disabled", "disabled");
    $button.addClass("btn-loader");
    $spinner.removeClass("hidden");
  });
}

if ($("#id_iban").length !== 0) {
  $("#id_iban").mask("SS00 0000 0000 0000 0000");
}

initFormValidation();

// #############################################################################
// FORM: CHANGE WARNING

function initChangeWarnings () {
  sessionStorage.removeItem("change_warnings");

  $body.on("input", "[data-change-warning]", function () {
    const $this = $(this);
    const $form = $this.parents("form");
    const name = $this.attr("name");
    let value = $this.val();
    let update_items = false;
    let $items = [];
    const $new_items = [];
    const items = sessionStorage.getItem("change_warnings");

    if ($this.attr("type") === "checkbox") {
      value = $this.is(":checked");
    }

    if (items) {
      $items = JSON.parse(items);
    }

    $.each($items, function (index) {
      const $item = $items[index];

      if ($item.name === name) {
        $item.value = value;
        update_items = true;
      }
    });

    if (!update_items) {
      $items.push({
        form: $form.attr("id"),
        name: name,
        reset: $this.attr("data-change-warning-reset"),
        value: value,
        warning: $this.attr("data-change-warning"),
      });
    }

    $.each($items, function (index) {
      const $item = $items[index];

      if ($item.reset !== "empty-value" || $item.value) {
        $new_items.push($item);
      }
    });

    sessionStorage.setItem("change_warnings", JSON.stringify($new_items));
  });

  $body.on("submit", "[data-change-warnings-dialog]", function () {
    const $this = $(this);
    const id = $this.attr("data-change-warnings-dialog");

    sessionStorage.setItem("dialog-submit", true);

    $("#" + id).submit();
  });

  $body.on("click", "[data-reset-change-warnings]", function () {
    sessionStorage.removeItem("change_warnings");
  });
}

function resetChangeWarnings ($form) {
  const id = $form.attr("data-change-warnings-dialog");

  let $items = [];
  const $new_items = [];
  const items = sessionStorage.getItem("change_warnings");

  if (items) {
    $items = JSON.parse(items);
  }

  $.each($items, function (index) {
    const $item = $items[index];

    if ($item.form !== id) {
      $new_items.push($item);
    }
  });

  if ($new_items.length === 0) {
    sessionStorage.removeItem("change_warnings");
  } else {
    sessionStorage.setItem("change_warnings", JSON.stringify($new_items));
  }

  sessionStorage.removeItem("dialog-submit");
}

function checkChangeWarnings ($form, $inputs) {
  let check = false;

  if (sessionStorage.getItem("dialog-submit")) {
    return false;
  }

  const items = sessionStorage.getItem("change_warnings");

  if (items) {
    const $items = JSON.parse(items);

    $inputs.each(function (index) {
      const $input = $inputs.eq(index);
      const name = $input.attr("name");

      $.each($items, function (index) {
        const $item = $items[index];

        if ($item.name === name) {
          check = true;
        }
      });
    });
  }

  if (check) {
    $("[data-dialog=\"check-warnings\"]").click();

    const interval = setInterval(function () {
      const $dialog = $("[data-change-warnings-dialog]");

      if ($dialog.length > 0) {
        clearInterval(interval);

        if ($dialog) {
          const items = sessionStorage.getItem("change_warnings");

          if (items) {
            const $items = JSON.parse(items);
            let content = "";

            $.each($items, function (index) {
              const $item = $items[index];

              if ($item.warning) {
                content += "<li>" + $item.warning + "</li>";
              }
            });

            if (content) {
              const $warnings = $("<ul>").html(content);

              $("[data-dialog-main]", $dialog).append($warnings);
              $dialog.attr("data-change-warnings-dialog", $form.attr("id"));
            }
          }
        }
      }
    }, 300);
  }

  return check;
}

initChangeWarnings();

// #############################################################################
// FORM: RELATIONSHIP

function initFormRelationship () {
  const $relationships = $("[data-relationship]");

  function toggleTargetIds ($relationship, trigger, index) {
    if ($relationship[0].id === "id_care_goal") {
      toggleTargetIdsPersonalData($relationship, trigger, index);
    } else {
      toggleTargetIdsGeneral($relationship, trigger, index);
    }
  }

  function toggleTargetIdsGeneral ($relationship, trigger, index) {
    const ids = $relationship.data("relationship");
    const classes = $relationship.data("relationshipClasses");

    if ($.isArray(ids)) {
      if ($relationship.val() === trigger) {
        const $show_ids = ids[index];

        $.each(ids, function (key, $items) {
          for (let i = 0; i < $items.length; i++) {
            const $target = $("#" + $items[i]);

            if ($show_ids.indexOf($items[i]) > -1) {
              $target.removeClass("hidden");
            } else {
              $target.addClass("hidden");
            }
          }
        });
      }

      if ($.isArray(classes)) {
        if ($relationship.val() === trigger) {
          const $show_classes = classes[index];

          $.each(classes, function (key, $items) {
            for (let i = 0; i < $items.length; i++) {
              const $target = $("." + $items[i]);

              if ($show_classes.indexOf($items[i]) > -1) {
                $target.removeClass("hidden");
              } else {
                $target.addClass("hidden");
              }
            }
          });
        }
      }
    } else {
      toggleTarget($relationship, trigger, index, ids);
    }
  }

  function toggleTargetIdsPersonalData ($relationship, trigger, index) {
    // loads the fieldset of the page according to the selected care goal

    const ids = $relationship.data("relationship");
    if ($.isArray(ids)) {
      if ($relationship.val() === trigger) {
        let input_values_old = {};
        let suffix_old = null;
        let input_values_new = {};
        let suffix_new = null;

        // for each realtionship, for each fieldset; if fieldset not hidden, get values of input elements
        $.each(ids, function (key, $items) {
          for (let i = 0; i < $items.length; i++) {
            const $fieldset_element = document.getElementById($items[i]);
            if ($fieldset_element && !$fieldset_element.classList.contains("hidden")) {
              const fieldset_result = get_input_values_and_id_suffix_of_fieldset($fieldset_element, input_values_old);
              input_values_old = fieldset_result[0];
              suffix_old = fieldset_result[1];
            }
          }
        });

        // toggle fieldsets visibility; then get new fieldset which is not hidden and get values of input elements
        toggleTargetIdsGeneral($relationship, trigger, index);

        $.each(ids, function (key, $items) {
          for (let i = 0; i < $items.length; i++) {
            const $fieldset_element = document.getElementById($items[i]);
            if ($fieldset_element && !$fieldset_element.classList.contains("hidden")) {
              const fieldset_result = get_input_values_and_id_suffix_of_fieldset($fieldset_element, input_values_new);
              input_values_new = fieldset_result[0];
              suffix_new = fieldset_result[1];
            }
          }
        });

        // if key of old array exists in new array, set new input value to old input value
        for (const [key, value_old] of Object.entries(input_values_old)) {
          if (input_values_new[key] !== undefined && input_values_old[key] !== undefined) {
            const id_old = [key, suffix_old].join("_");
            const id_new = [key, suffix_new].join("_");
            const input_element_new = document.getElementById(id_new);
            const input_element_old = document.getElementById(id_old);

            if (input_element_new) {
              if (input_element_new.type === "checkbox") {
                input_element_new.checked = input_element_old.checked;
              } else {
                input_element_new.value = input_values_old[key];
              }
            }
          }
        }
      }
    } else {
      toggleTarget($relationship, trigger, index, ids);
    }
  }

  function get_input_values_and_id_suffix_of_fieldset (fieldset_element, input_values) {
    let suffix = null;
    const input_elements_of_fieldset = fieldset_element.getElementsByTagName("input");

    // add each input element to input_old_values; key == id without suffix; value == value of input element
    for (let element_id = 0; element_id < input_elements_of_fieldset.length; element_id++) {
      const id_splitted = split_suffix(input_elements_of_fieldset[element_id].getAttribute("id"));
      const id_base = id_splitted[0];
      suffix = id_splitted[1];
      input_values[id_base] = input_elements_of_fieldset[element_id].value;
    }

    return [input_values, suffix];
  }

  function split_suffix (input) {
    const input_splitted = input.split("_");
    const suffix = input_splitted[input_splitted.length - 1];
    const output = input_splitted.slice(0, input_splitted.length - 1).join("_");
    return [output, suffix];
  }

  function toggleTarget ($relationship, trigger, index, ids) {
    const $id = ids.split(",");

    const $target = $("#" + $id[index]);
    let value = $relationship.val();

    if ($relationship.is(":checkbox") && value === "on") {
      if ($relationship.is(":checked")) {
        value = "1";
      } else {
        value = "0";
      }
    }

    if (value === trigger) {
      $target.removeClass("hidden");
    } else {
      $target.addClass("hidden");
    }
  }

  function toggleTargets (el) {
    const index = $relationships.index(el);
    const $relationship = $relationships.eq(index);
    const trigger = $relationship.data("relationship-trigger").toString();
    const $triggers = trigger.split(",");

    if (!$triggers.includes($relationship.val().toString())) {
      // If a trigger is selected which is not defined in the relationship; Add a relationship for this value so the
      // displayed elements of this relationship get updated

      const currentRelationships = $relationship.data("relationship");
      if (currentRelationships.length > 0) {
        if ($triggers.length >= currentRelationships.length) {
          currentRelationships.push([]);
          $relationship[0].setAttribute("data-relationship", currentRelationships);
        }
      }
      // if relationship classes are defined in relationship for toggling only one field and not fieldset
      // Add a relationship for this value so th displayed elements of this relationship get updated
      if ($relationship.data("relationshipClasses")) {
        const currentRelationshipClasses = $relationship.data("relationshipClasses");
        if (currentRelationshipClasses.length > 0) {
          if ($triggers.length >= currentRelationshipClasses.length) {
            currentRelationshipClasses.push([]);
            $relationship[0].setAttribute("data-classes", currentRelationshipClasses);
          }
        }
      }

      $triggers.push($relationship.val());
    }

    if ($triggers.length === 1) {
      toggleTargetIds($relationship, trigger, 0);
    } else {
      for (let i = 0; i < $triggers.length; i++) {
        toggleTargetIds($relationship, $triggers[i], i);
      }
    }
  }

  $relationships.each(function () {
    toggleTargets(this);
  });

  $relationships.on("input", function () {
    toggleTargets(this);
  });
}

initFormRelationship();

// #############################################################################
// FORM: RELATIONSHIP TOGGLE

function initFormToggleRelationship () {
  const $relationships = $("[data-relationship-toggle]");

  function toggleTargets (el) {
    const index = $relationships.index(el);
    const $relationship = $relationships.eq(index);
    const triggers = $relationship.data("relationship-trigger").toString();
    const $triggers = triggers.split(",");
    const value = $relationship.val();
    const $target = $("#" + $relationship.data("relationship-toggle"));

    if ($relationship.is(":checkbox")) {
      if ($triggers.indexOf(value) > -1) {
        if ($relationship.is(":checked")) {
          $target.removeClass("hidden");
        } else {
          $target.addClass("hidden");
        }
      }
    } else {
      if ($triggers.indexOf(value) > -1) {
        $target.removeClass("hidden");
      } else {
        $target.addClass("hidden");
      }
    }
  }

  $relationships.each(function () {
    toggleTargets(this);
  });

  $relationships.on("input", function () {
    toggleTargets(this);
  });
}

initFormToggleRelationship();


// #############################################################################
// FORM: COPY FIELD VALUE TO ANOTHER FIELD(S)

function initCopyFieldValues () {
  // if data contains "copy-relation", the key value of the dictionary field will be copied into the other fields defined
  const $copyRelationship = $("[data-copy-relation]");
  function copy_value (el) {
    const index = $copyRelationship.index(el);
    const $relationship = $copyRelationship.eq(index);
    const fields_to_be_filled_seperated = $relationship.data("copy-relation").toString().split(",");
    for (let i = 0; i < fields_to_be_filled_seperated.length; i++) {
      document.getElementById("id_" + fields_to_be_filled_seperated[i]).value = $relationship.val();
    }
  }

  $copyRelationship.on("input", function () {
    copy_value(this);
  });
}

initCopyFieldValues();


function getCSRFToken () {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = jQuery.trim(cookies[i]);
      if (cookie.substring(0, 10) === ("csrftoken" + "=")) {
        cookieValue = decodeURIComponent(cookie.substring(10));
        break;
      }
    }
  }
  return cookieValue;
}

// #############################################################################
// FORM: VALUE RELATIONSHIP
// see CapabilityAnalysisForm for an example where data-value-relationship-text is depending on the selected choice

function initFormValueRelationship () {
  $body.on("change", "[data-value-relationship-source]", function (e) {
    const sourceId = e.target.id;
    const relationshipSourceType = e.target.getAttribute("data-value-relationship-source-type");
    const relationshipSource = e.target.getAttribute("data-value-relationship-source");
    const relationshipTarget = e.target.getAttribute("data-value-relationship-target");
    const targetId = sourceId.split(relationshipSource)[0] + relationshipTarget;

    if (relationshipSourceType === "radio_buttons") {
      const sourceText = e.target.getAttribute("data-value-relationship-text");
      $("#" + targetId).val(sourceText);
    } else if (relationshipSourceType === "select") {
      const sourceText = e.target.selectedOptions[0].getAttribute("data-value-relationship-text");

      if (relationshipTarget === "note") {
        const useTemplates = $("#id_templates").is(":checked");

        if (useTemplates && sourceText) {
          $(tinyMCE.get(targetId).getBody()).html(sourceText);
        }
      } else {
        if (sourceText) {
          $(tinyMCE.get(targetId).getBody()).html(sourceText);
        }
      }
    }
  });
}

initFormValueRelationship();

// #############################################################################
// GET PROJEKT
// returns the id of the current project (autark, volkshilfe, miteinander)

async function getProject () {
  const response = await fetch("/act/personal_data/get_project/", {
    headers: {
      "X-CSRFToken": getCSRFToken(),
    },
  });
  return await response.text();
}

// #############################################################################
// FORM: BIRTHDAY TO SVN NUMBER
const func = async function () {
  const project = await getProject(); // get id of edok project
  const $svnr = $("#id_ssn");
  const $date_of_birth = new Date($("#id_birthday").val());
  let date_of_birth_month;
  let date_of_birth_day;
  const date_of_birth_year = $date_of_birth.getFullYear();
  const $id_birthday_13th_month = $("#id_birthday_13th_month").val().toString();

  // OEZIV does not want this feature
  if (project === "5") {
    return;
  }

  if ($svnr.val() === "" && date_of_birth_year.toString().length === 4) {
    const date = $date_of_birth.getDate();
    const month = $date_of_birth.getMonth();
    const year = $date_of_birth.getFullYear();

    if (month + 1 < 10) {
      date_of_birth_month = "0" + (month + 1);
    } else {
      date_of_birth_month = (month + 1);
    }

    if (date < 10) {
      date_of_birth_day = "0" + date;
    } else {
      date_of_birth_day = date;
    }

    let svnr = "";

    if (project === "1") { // project is autark, remove space in svnr
      svnr = "0000" + date_of_birth_day + date_of_birth_month + year.toString().substr(-2);
    } else {
      svnr = "0000 " + date_of_birth_day + date_of_birth_month + year.toString().substr(-2);
    }

    $svnr.val(svnr);
    if (document.getElementById("id_ssn_unknown").checked === true) {
      $svnr.val("");
    }
  } else if ($svnr.val() === "" && $id_birthday_13th_month.toString().length === 10) {
    const $date_of_birth = $("#id_birthday_13th_month").val();
    const $days = $date_of_birth.slice(0, 2);
    const $month = $date_of_birth.slice(3, 5);
    const $year = $date_of_birth.slice(6, 10);

    if ($month === "13") {
      date_of_birth_month = $month;
    }
    date_of_birth_day = $days;


    if ($month === "13" && (document.getElementById("id_ssn_13th_month").checked === true)) {
      let svnr = "";
      if (project === "1") { // project is autark, remove space in svnr
        svnr = "0000" + date_of_birth_day + date_of_birth_month + $year.toString().substr(-2);
      } else {
        svnr = "0000 " + date_of_birth_day + date_of_birth_month + $year.toString().substr(-2);
      }
      $svnr.val(svnr);
    }
    if (document.getElementById("id_ssn_unknown").checked === true) {
      $svnr.val("");
    }
  }
};
function initBirtdayToSvnNumber () {
  $body.on("input", "#id_birthday", func);
  $body.on("input", "#id_birthday_13th_month", func);
}

initBirtdayToSvnNumber();

// #############################################################################
// FORM: CLEAR SSN FIELD IF UNKNOWN

function clearSnnNumber () {
  $body.on("change", "#id_ssn_unknown", function () {
    if (document.getElementById("id_ssn_unknown").checked === true) {
      const $svnr = $("#id_ssn");
      $svnr.val("");
    }
  });
}

clearSnnNumber();

// // #############################################################################
// // FORM: DISABLE TEXTFIELD BIRTHDAY IF 13TH BIRTHDAY SSN IS SELECTED

function toggleBirthday13thMonth (clear) {
  const $id_birthday = $("#id_birthday");
  const $id_birthday_13th_month = $("#id_birthday_13th_month");
  const $id_ssn = $("#id_ssn");
  const $id_ssn_13th_month = $("#id_ssn_13th_month");
  if ($id_ssn_13th_month.is(":visible")) {
    if (document.getElementById("id_ssn_13th_month").checked === true) {
      $id_birthday.val("");
      // don't clear on load
      if (clear) {
        $id_ssn.val("");
      }
      $id_birthday.addClass("hidden");
      $id_birthday.parent().parent().addClass("hidden");
      $id_birthday_13th_month.removeClass("hidden");
      $id_birthday_13th_month.parent().parent().removeClass("hidden");
    } else {
      $id_birthday_13th_month.val("");
      // don't clear on load
      if (clear) {
        $id_ssn.val("");
      }
      $id_birthday.removeClass("hidden");
      $id_birthday.parent().parent().removeClass("hidden");
      $id_birthday_13th_month.addClass("hidden");
      $id_birthday_13th_month.parent().parent().addClass("hidden");
    }
  }
}

$body.on("change", "#id_ssn_13th_month", toggleBirthday13thMonth);
toggleBirthday13thMonth(false);


//
// #############################################################################
// FORM: COPY ACT

$x.delegateEvent.on(document, "click", "[data-copy-act]", function (e) {
  e.preventDefault();

  const $form = document.getElementById("add-act-form");
  const $formDataCopyActToDepartment = $("#copy_act_to_department").serializeArray();
  const $formData = new FormData($form);

  $formDataCopyActToDepartment.forEach(function (fields) {
    $formData.append(fields.name, fields.value);
  });


  $x.ajax.post(this.closest("form").action, {
    dataType: "json",
    data: $formData,
    success: ($data) => {
      window.location.href = $data.redirect;
    },
  });
});

// #############################################################################
// FORM: SUBMIT WARNING

let displayedDuplicates = false;

$x.delegateEvent.on(document, "click", "[data-submit-warning]", function (e) {
  if (displayedDuplicates === false) {
    displayedDuplicates = true;
    submitWarning = 1;
  } else {
    if (submitWarning === 1) {
      submitWarningIgnored = 1;
      e.preventDefault();
      const $submitWarning = document.getElementById("submit-warning");
      $submitWarning.click();
    }
  }
});

$x.delegateEvent.on(document, "click", "[data-submit-warning-dialog] button", function (e) {
  e.preventDefault();

  const id = this.closest("[data-submit-warning-dialog]").dataset.submitWarningDialog;
  const $button = document.getElementById(id).querySelector("[data-submit-warning]");

  submitWarning = 0;
  submitWarningIgnored = 1;
  $button.click();
});


// #############################################################################
// MAIN NAVIGATION

function initDesktopMainNav () {
  const $main_nav_mobile = $("[data-main-nav-mobile]");
  const $main_nav = $("[data-main-nav-wrapper]");

  const $items = $("[data-main-nav-item]", $main_nav);
  const $links = $("[data-main-nav-link]", $items);
  const $all_main_links = $("[data-main-nav-link], [data-main-nav-extra-link]", $items);
  const $all_links = $("a", $items);

  let hover_timeout;

  function showSubmenu ($current_item) {
    if ($main_nav_mobile.is(":visible")) {
      return;
    }

    $("[data-main-nav-link]", $current_item).attr("aria-expanded", "true");
  }

  function hideSubmenu () {
    if ($main_nav_mobile.is(":visible")) {
      return;
    }

    $links.attr("aria-expanded", "false");
  }

  $items.on("mouseenter", function () {
    const $current_item = $(this);

    hideSubmenu();
    showSubmenu($current_item);

    clearTimeout(hover_timeout);
  }).on("mouseleave", function () {
    hover_timeout = setTimeout(function () {
      hideSubmenu();
    }, 500);
  });

  $all_links.on("keydown", function (event) {
    if (event.shiftKey && event.keyCode === 9) {
      if ($all_links.index(this) === 0) {
        hideSubmenu();
      }
    } else if (event.keyCode === 9) {
      if ($all_links.index(this) === $all_links.length - 1) {
        hideSubmenu();
      }
    }
  });

  $all_main_links.on("focus", function () {
    const $current_item = $(this).parents("[data-main-nav-item]");

    hideSubmenu();
    showSubmenu($current_item);
  });

  $(document).on("click", function () {
    hideSubmenu();
  });

  $links.on("click", function (event) {
    event.preventDefault();
  }).on("touchstart", function () {
    const $current_link = $(this);
    const $current_item = $current_link.parents("[data-main-nav-item]");

    if ($current_link.attr("aria-expanded") === "true") {
      hideSubmenu();
    } else {
      showSubmenu($current_item);
    }
  });

  $window.on("resize orientationchange", function () {
    hideSubmenu();
  });
}

initDesktopMainNav();

function initMobileMainNav () {
  const $main_nav_mobile = $("[data-main-nav-mobile]");
  const $main_nav = $("[data-main-nav-wrapper]");
  const $main_nav_links = $("[data-main-nav-link]", $main_nav);

  $main_nav_links.on("click", function (event) {
    event.preventDefault();

    if (!$main_nav_mobile.is(":visible")) {
      return;
    }

    const $main_nav_links = $(this);
    const $main_nav_sub = $main_nav_links.next("[data-main-nav-sub]");

    if ($main_nav_sub.is(":visible")) {
      $main_nav_links.attr("aria-expanded", "false");
    } else {
      $main_nav_links.attr("aria-expanded", "true");
    }
  });
}

initMobileMainNav();

// #############################################################################
// FIX SUB NAVIGATION

function fixSubNav () {
  const $sub_nav = $("#sub_nav");

  if ($sub_nav.length === 0) {
    return;
  }

  const height = $sub_nav.outerHeight() + parseInt($sub_nav.css("top"), 10);

  if (height <= $window.height()) {
    $sub_nav.addClass("sticky");
  } else {
    $sub_nav.removeClass("sticky");
  }
}

$window.on("resize", function () {
  fixSubNav();
});

fixSubNav();

// #############################################################################
// ACCORDION

function initAccordion () {
  $body.on("click", "[data-accordion]", function (event) {
    event.preventDefault();

    const $this = $(this);
    const $content = $($this.attr("href"));

    if ($this.attr("aria-expanded") === "true") {
      $content.addClass("hidden");
      $this.attr("aria-expanded", "false");
    } else {
      $content.removeClass("hidden");
      $this.attr("aria-expanded", "true");
    }
  });
}

initAccordion();

// #############################################################################
// HISTORY

function initHistorySubjectText () {
  $body.on("change", "#id_keyword", function () {
    const history_subject = $("[data-history-subject]").data("history-subject");

    const keep_note = $("#id_keep_note").is(":checked");

    if (keep_note === false) {
      $.get(history_subject + this.value, function (data) {
        if (data && data !== "None") {
          $(tinyMCE.get("id_note").getBody()).html(data);
          $("#id_note").val(data);
        }
      });
    }
  });
}

initHistorySubjectText();

// #############################################################################
// DATA TABLE

function initDataTable (table, $options) {
  const $table = $("[data-" + table + "]");
  const $inputs = $("[data-input]");
  const $selects = $("[data-select]");
  let dom_value = "";

  if ($table.length === 0) {
    return;
  }

  const ordering = $table.attr("data-ordering");

  // Custom classes

  $.fn.dataTable.ext.classes.sInfo = "datatable-entries-info";
  $.fn.dataTable.ext.classes.sLength = "datatable-entries field-select-inline-wrapper hidden-print";
  $.fn.dataTable.ext.classes.sLengthSelect = "datatable-select field-select hidden-print";
  $.fn.dataTable.ext.classes.sPageButton = "datatable-paginate-button font-opensans-semibold";
  $.fn.dataTable.ext.classes.sPageButtonActive = "current";
  $.fn.dataTable.ext.classes.sPaging = "datatable-paging hidden-print paging_";
  $.fn.dataTable.ext.classes.sProcessing = "datatable-processing";
  $.fn.dataTable.ext.classes.sSortAsc = "sort-asc";
  $.fn.dataTable.ext.classes.sSortColumn = "sort-";
  $.fn.dataTable.ext.classes.sSortDesc = "sort-desc";
  $.fn.dataTable.ext.classes.sStripeEven = "even";
  $.fn.dataTable.ext.classes.sStripeOdd = "odd";
  $.fn.dataTable.ext.classes.sTable = "datatable-table";
  $.fn.dataTable.ext.classes.sWrapper = "datatable-wrapper";
  $.fn.dataTable.ext.classes.sRowEmpty = "datatable-empty";

  if ($table.data("disable-buttons") === 1) {
    dom_value = "<\"row\"<\"col-12\"<\"datatable-info-left hidden-print\">><\"col-12\"rt><\"col-12 col-md-auto\"<\"datatable-info-left hidden-print\"li>><\"col\"<\"datatable-info-right\"p>>>";
  } else {
    dom_value = "<\"row\"<\"col-12\"<\"datatable-info-left hidden-print\"B>><\"col-12\"rt><\"col-12 col-md-auto\"<\"datatable-info-left hidden-print\"li>><\"col\"<\"datatable-info-right\"p>>>";
  }

  const $buttons = [
  ];

  if ($table.data("hide-filter-button") !== 1) {
    $buttons.push({
      text: LANGUAGE.resetButton,
      className: "field-button hidden-print",
      action: function (e, $dt) {
        $dt.columns().search("").draw();

        $inputs.val("");

        $selects.each(function (index) {
          const $select = $selects.eq(index);
          const value = $("[selected]", $select).val();

          if (value) {
            $select.val(value);
          } else {
            $select.val("");
          }
        });
      },
    });
  }

  if ($table.data("show-csv-export") === 1) {
    $buttons.push({
      text: LANGUAGE.exportCSV,
      className: "field-button hidden-print",
      extend: "csv",
      customize: function (csv) {
        return csv;
      },
    });
  }

  if ($table.data("show-excel-export") === 1) {
    $buttons.push({
      text: LANGUAGE.exportExcel,
      className: "field-button hidden-print",
      extend: "excel",
      customize: function (excel) {
        return excel;
      },
    });
  }

  const $defaults = {
    autoWidth: false,
    ajax: {
      url: $table.data(table),
    },
    // Custom Cursor for processing
    fnPreDrawCallback: function () {
      $("body").css("cursor", "wait");
      return true;
    },
    fnDrawCallback: function () {
      $("body").css("cursor", "default");
    },
    buttons: {
      dom: {
        container: {
          className: "datatable-buttons",
        },
      },
      buttons: $buttons,
    },
    dom: dom_value,
    language: {
      sEmptyTable: LANGUAGE.sEmptyTable,
      sInfo: LANGUAGE.sInfo,
      sInfoEmpty: LANGUAGE.sInfoEmpty,
      sInfoThousands: ".",
      sLengthMenu: LANGUAGE.sLengthMenu,
      sProcessing: LANGUAGE.sProcessing,
      aria: {
        sortDescending: LANGUAGE.oAria.sortDescending,
        sortAscending: LANGUAGE.oAria.sortAscending,
      },
    },
    ordering: parseInt(ordering) ? true : false,
    columnDefs: [{
      sortable: false,
      targets: "no-sort",
    }],
    pagingType: "numbers",
    processing: true,
    responsive: true,
    serverSide: true,
    stateSave: true,
    initComplete: function () {
      const $api = this.api();
      let timeout;

      $(".datatable-select").attr("aria-label", LANGUAGE.entries).after("<div class=\"field-select-overlay\">");

      $inputs.on("input", function () {
        const $input = $inputs.eq($inputs.index(this));
        const column = $api.column($input.attr("data-column"));
        const dataColumnValue = document.querySelectorAll("[data-column='" + this.dataset.column + "']");
        const that = this;

        dataColumnValue.forEach(function (el) {
          el.value = that.value;
        });
        const value = this.value;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
          column.search(value).draw();
        }, 200);
      });

      $selects.on("change", function () {
        const $select = $selects.eq($selects.index(this));
        const column = $api.column($select.attr("data-column"));
        const dataColumnValue = document.querySelectorAll("[data-column='" + this.dataset.column + "']");
        const that = this;

        dataColumnValue.forEach(function (el) {
          el.value = that.value;
        });

        column.search($select.val()).draw();
      });

      // Restore saved filter values

      const $state = $api.state.loaded();

      if ($state) {
        $api.columns().eq(0).each(function (index) {
          const $search = $state.columns[index].search;

          if ($search) {
            const search = $search.search;

            if (search) {
              // get search-element (input or select) for data-column
              let el = $($api.column(index).footer()).find("select")[0];

              if (!el) {
                el = $($api.column(index).footer()).find("input")[0];
              }

              // set value of all data-column elements to search (all two filter types)
              if (el) {
                const dataColumnValue = document.querySelectorAll("[data-column='" + el.dataset.column + "']");
                dataColumnValue.forEach(function (el) {
                  el.value = search;
                });
              }
            }
          }
        });
      }

      $("select#department").change(function () {
        updateSupervisorList($(this).val());
        Object.values($selects).forEach((select) => {
          if (select.id === "id_supervisor") {
            $api.column(select.getAttribute("data-column")).search("").draw();
          }
        });
      });
    },
  };
  $.extend($defaults, $options);

  window.datatable = $table.DataTable($defaults);
}

function updateSupervisorList (department_id = null) {
  // draw() is required after this is called
  $.ajax({
    data: {
      department_id: department_id,
    },
    type: "POST",
    url: "get_supervisor_for_act/",
    success: function (data) {
      $("#supervisor option").addClass("hidden");
      $("#supervisor option[value='']").removeClass("hidden");
      document.getElementById("supervisor").value = "";
      const supervisor_list = document.getElementById("supervisor");
      for (let supervisor_list_index = 0; supervisor_list_index < supervisor_list.length; supervisor_list_index++) {
        const current_val = supervisor_list.options[supervisor_list_index].value;
        for (let data_index = 0; data_index < data.length; data_index++) {
          if (current_val === data[data_index]) {
            supervisor_list.options[supervisor_list_index].classList.remove("hidden");
            break;
          }
        }
      }
    },
  });
}

$body.on("change", ".user-relevant-choice", function () {
  const $period_from = document.getElementById("id_period_from");
  const $period_to = document.getElementById("id_period_to");
  const $statistic_id = document.getElementById("id_statistic_choice");

  $.ajax({
    data: {
      period_to: $period_to.value,
      period_from: $period_from.value,
      statistic_id: $statistic_id.value,
    },
    type: "POST",
    url: "get_users_for_statistic/",
    success: function (data) { // receive all employees with working between to and from date
      const $user_choice = document.getElementById("user_choice");
      const $one_user = document.getElementById("one_user");
      // check if employees are displayed in dropdown or checkbox multiple select
      if ($user_choice || $one_user) {
        // build fieldset that will be displayed
        const $fieldset = document.createElement("fieldset");
        const $legend = document.createElement("legend");
        $legend.classList.add("legend-sub");
        $legend.textContent = "Benutzerauswahl";
        $fieldset.appendChild($legend);
        const $outer_div = document.createElement("div");
        $outer_div.classList.add("row", "sm-gutters");
        $fieldset.appendChild($outer_div);
        // create checkboxes for all employees
        for (let data_index = 0; data_index < data.length; data_index++) {
          const $inner_div = document.createElement("div");
          $inner_div.classList.add("col-4");
          $outer_div.appendChild($inner_div);

          const $label = document.createElement("label");
          $label.classList.add("field-checkbox");

          const $input = document.createElement("input");
          $inner_div.appendChild($label);
          $input.id = "id_user_choice_" + data_index;
          $input.type = "checkbox";
          $input.name = "user_choice";
          $input.value = data[data_index][2];

          const $span_first = document.createElement("span");
          $span_first.classList.add("field-checkbox-indicator");

          const $span_second = document.createElement("span");
          $span_second.classList.add("field-checkbox-title");
          $span_second.textContent = data[data_index][0] + " " + data[data_index][1];

          $label.appendChild($input);
          $label.appendChild($span_first);
          $label.appendChild($span_second);
        }
        // remove fieldset in html and add new fieldset to parent node
        const $parent_node = document.getElementById("user_choice").getElementsByTagName("fieldset").item(0).parentNode;
        document.getElementById("user_choice").getElementsByTagName("fieldset").item(0).remove();
        $parent_node.appendChild($fieldset);

        // dropdown
        const $one_user_options = document.querySelectorAll("#id_one_user option");
        // set all options of select to hidden
        $one_user_options.forEach(
          function (el) {
            el.classList.add("hidden");
          }
        );
        for (let one_user_select_index = 0; one_user_select_index < $one_user_options.length; one_user_select_index++) {
          const current_val = $one_user_options[one_user_select_index].value;
          for (let data_index = 0; data_index < data.length; data_index++) {
            if (current_val === data[data_index][2]) {
              $one_user_options[one_user_select_index].classList.remove("hidden");
              break;
            }
          }
        }
      }
    },
  });
});
// #############################################################################
// DATA TABLE: ACT

function initDataTableAct () {
  if (!$.fn.dataTable) {
    return;
  }
  const options = $("#options").attr("options");
  if (options) {
    const data_table_options = JSON.parse(options);
    initDataTable("act-table", data_table_options);
  }

  $("[data-act-table]")
    .on("xhr.dt", function (e, settings, json, xhr) {
      const tableCounts = document.querySelector("#table-counts");
      if (tableCounts != null) {
        if (parseInt(tableCounts.dataset.draw) < parseInt(json.draw)) {
          tableCounts.dataset.draw = json.draw;
          if (json.counts) {
            tableCounts.innerHTML = json.counts;
          } else {
            tableCounts.innerHTML = "";
          }
        }
      }
    });
}

initDataTableAct();


// DATA TABLE: SCHOOL
function initDataTableSchool () {
  if (!$.fn.dataTable) {
    return;
  }
  const options = $("#options").attr("options");
  if (options) {
    const data_table_options = JSON.parse(options);
    initDataTable("school-table", data_table_options);
  }
}

initDataTableSchool();

// #############################################################################
// DATA TABLE: CLIENT SEARCH

function initDataTableClientSearch () {
  if (!$.fn.dataTable) {
    return;
  }

  const options = {
    columns: [
      {
        data: "label",
        className: "nowrap",
      },
      {
        data: "svnr",
        className: "nowrap",
      },
      {
        data: "day_of_birth",
        className: "nowrap",
      },
      {
        data: "portal_id",
        className: "nowrap",
      },
      {
        data: "supervisor",
      },
      {
        data: "supervisor_phone_number",
      },
      {
        data: "archived",
      },
    ],
    pageLength: 25,
    order: [[0, "asc"]],
  };

  initDataTable("client-search-table", options);
}

initDataTableClientSearch();

// #############################################################################
// DATA TABLE: COMPANY

function initDataTableCompany () {
  if (!$.fn.dataTable) {
    return;
  }

  const options = {
    columns: [
      {
        data: "company",
        className: "nowrap",
      },
      {
        data: "address",
      },
      {
        data: "zip_code",
      },
      {
        data: "branch",
      },
      {
        data: "supervisor",
      },
      {
        data: "status",
      },
      {
        data: "verified",
      },
    ],
    pageLength: 25,
    order: [[0, "asc"]],
  };

  initDataTable("company-table", options, false);
}

initDataTableCompany();


// #############################################################################
// DATA INIT COMPANY ACT LIST

function initDataTableCompanyActList () {
  if (!$.fn.dataTable) {
    return;
  }

  const options = {
    columns: [
      {
        data: "company_act_type",
        className: "company-act-type nowrap",
      },
      {
        data: "company_label",
      },
      {
        data: "company_last_history_date",
      },
      {
        data: "company_district",
      },
      {
        data: "company_supervisor",
      },
      {
        data: "company_next_history_date",
      },
      {
        data: "company_begin_date",
      },
      {
        data: "company_year_closed",
      },
    ],
    pageLength: 25,
    order: [[0, "asc"]],
  };

  initDataTable("company-act-list-table", options, false);
}

initDataTableCompanyActList();


// #############################################################################
// DATA TABLE: COMPANY CLIENT SEARCH

function initDataTableCompanyClientSearch () {
  if (!$.fn.dataTable) {
    return;
  }

  initDataTable("company-client-search-table", {
    columns: [
      {
        data: "label",
        className: "nowrap",
      },
      {
        data: "company",
        className: "company-type nowrap",
      },
      {
        data: "address",
      },
      {
        data: "zip_code",
      },
      {
        data: "district",
      },
      {
        data: "project",
      },
      {
        data: "supervisor",
      },
      {
        data: "type",
      },
      {
        data: "begin",
      },
      {
        data: "end",
      },
    ],
    pageLength: 25,
    order: [[0, "asc"]],
  });

  initDataTable("autark-eaass-company-client-search-table", {
    columns: [
      {
        data: "label",
        className: "nowrap",
      },
      {
        data: "company",
        className: "company-type nowrap",
      },
      {
        data: "supervisor",
      },
      {
        data: "type",
      },
      {
        data: "end",
      },
    ],
    pageLength: 25,
    order: [[0, "asc"]],
  });
}

initDataTableCompanyClientSearch();


// #############################################################################
// DATA TABLE: PRACTISE

function initDataTablePractise () {
  if (!$.fn.dataTable) {
    return;
  }

  initDataTable("practise-table", {
    columns: [
      {
        data: "type",
      },
      {
        data: "practise_begin",
      },
      {
        data: "company",
      },
      {
        data: "contact",
      },
      {
        data: "duration",
      },
      {
        data: "feedback",
      },
      {
        data: "edit",
        className: "edit",
      },
      {
        data: "delete",
        className: "delete",
      },
    ],
  });

  initDataTable("autark-practise-table", {
    columns: [
      {
        data: "type",
      },
      {
        data: "practise_begin",
      },
      {
        data: "practise_end",
      },
      {
        data: "company",
      },
      {
        data: "contact",
      },
      {
        data: "feedback",
      },
      {
        data: "edit",
        className: "edit",
      },
      {
        data: "delete",
        className: "delete",
      },
    ],
  });

  initDataTable("autark-bas-practise-table", {
    columns: [
      {
        data: "practise_begin",
      },
      {
        data: "practise_end",
      },
      {
        data: "type",
      },
      {
        data: "apprenticeship",
      },
      {
        data: "company",
      },
      {
        data: "edit",
        className: "edit",
      },
      {
        data: "delete",
        className: "delete",
      },
    ],
  });
  initDataTable("autark-cf-practise-table", {
    columns: [
      {
        data: "practise_begin",
      },
      {
        data: "practise_end",
      },
      {
        data: "company",
      },
      {
        data: "edit",
        className: "edit",
      },
      {
        data: "delete",
        className: "delete",
      },
    ],
  });
  initDataTable("homerun-practise-table", {
    columns: [
      {
        data: "practise_begin",
      },
      {
        data: "practise_end",
      },
      {
        data: "type_of_training",
      },
      {
        data: "apprenticeship",
      },
      {
        data: "company",
      },
      {
        data: "edit",
        className: "edit",
      },
      {
        data: "delete",
        className: "delete",
      },
    ],
  });
}

initDataTablePractise();

// #############################################################################
// DATA TABLE: LEARNING SUPPORT

function initDataTableLearningSupport () {
  if (!$.fn.dataTable) {
    return;
  }
  const options = $("#options").attr("options");
  if (options) {
    const data_table_options = JSON.parse(options);
    initDataTable("learning-support-table", data_table_options);
  }
}
initDataTableLearningSupport();

// #############################################################################
// DATA TABLE: APPRENTICESHIP SEARCH

function initDataTableApprenticeshipSearch () {
  if (!$.fn.dataTable) {
    return;
  }

  initDataTable("apprenticeship-search-table", {
    columns: [
      {
        data: "apprenticeship_type",
      },
      {
        data: "status",
      },
      {
        data: "apprenticeship",
      },
      {
        data: "company",
      },
      {
        data: "begin",
      },
      {
        data: "end",
      },
      {
        data: "edit",
        className: "edit",
      },
      {
        data: "delete",
        className: "delete",
      },
    ],
  });
}

initDataTableApprenticeshipSearch();

// #############################################################################
// DATA TABLE: REFERENCE PERSON

function initDataTableReferencePerson () {
  if (!$.fn.dataTable) {
    return;
  }

  initDataTable("reference-person-table", {
    columns: [
      {
        data: "name",
      },
      {
        data: "relationship",
      },
      {
        data: "phone_number",
      },
      {
        data: "email",
      },
      {
        data: "edit",
        className: "edit",
      },
      {
        data: "delete",
        className: "delete",
      },
    ],
  });
}

initDataTableReferencePerson();

// #############################################################################
// DATA TABLE: COMPANY CONTACT PERSON

function initDataTableCompanyContactPerson () {
  if (!$.fn.dataTable) {
    return;
  }

  initDataTable("company-contact-person-table", {
    columns: [
      {
        data: "name",
      },
      {
        data: "function",
      },
      {
        data: "phone_number",
      },
      {
        data: "email",
      },
      {
        data: "edit",
        className: "edit",
      },
      {
        data: "delete",
        className: "delete",
      },
    ],
  });
}

initDataTableCompanyContactPerson();

// #############################################################################
// DATA TABLE: PROFESSIONAL SCHOOL

function initDataTableProfessionalSchool () {
  if (!$.fn.dataTable) {
    return;
  }

  initDataTable("professional-school-table", {
    columns: [
      {
        data: "name",
      },
      {
        data: "class",
      },
      {
        data: "begin",
      },
      {
        data: "end",
      },
      {
        data: "positive_graduation",
      },
      {
        data: "edit",
        className: "edit",
      },
      {
        data: "delete",
        className: "delete",
      },
    ],
  }, true);

  initDataTable("autark-cf-professional-school-table", {
    columns: [
      {
        data: "qualification",
      },
      {
        data: "educational_institution",
      },
      {
        data: "field_of_work",
      },
      {
        data: "edit",
        className: "edit",
      },
      {
        data: "delete",
        className: "delete",
      },
    ],
  }, true);
}

initDataTableProfessionalSchool();

// #############################################################################
// DATA TABLE: HISTORY

function initDataTableHistory () {
  if (!$.fn.dataTable) {
    return;
  }

  const options = $("#options").attr("options");
  if (options) {
    const data_table_options = JSON.parse(options);
    initDataTable("history-folder-table", data_table_options);
  }
  if (options) {
    const data_table_options = JSON.parse(options);
    initDataTable("management-history-folder-table", data_table_options);
  }
  if (options) {
    const data_table_options = JSON.parse(options);
    initDataTable("history-table", data_table_options);
  }
  if (options) {
    const data_table_options = JSON.parse(options);
    initDataTable("management-history-table", data_table_options);
  }

  initDataTable("company-chat-table", {
    columns: [
      {
        data: "date",
      },
      {
        data: "note",
      },
      {
        data: "creator",
      },
      {
        data: "edit",
        className: "edit",
      },
      {
        data: "delete",
        className: "delete",
      },
    ],
    order: [[0, "desc"]],
  });

  initDataTable("company-history-table", {

    columns: [
      {
        data: "date",
      },
      {
        data: "form_type",
      },
      {
        data: "keyword",
      },
      {
        data: "note",
      },
      {
        data: "content",
      },
      {
        data: "attachment",
        className: "attachment",
      },
      {
        data: "edit",
        className: "edit",
      },
      {
        data: "delete",
        className: "delete",
      },
    ],
    order: [[0, "desc"]],
  });
}

initDataTableHistory();

// #############################################################################
// DATA TABLE PARALLEL CARE OVERVIEW

function initDataTableParallelCareOverview () {
  if (!$.fn.dataTable) {
    return;
  }

  initDataTable("parallel-care-table", {
    columns: [
      {
        data: "supervisor",
        className: "nowrap",
      },
      {
        data: "from_supervisor",
        className: "nowrap",
      },
      {
        data: "to_supervisor",
        className: "nowrap",
      },
      {
        data: "from_document",
        className: "nowrap",
      },
      {
        data: "to_document",
        className: "nowrap",
      },
      {
        data: "ssn",
      },
      {
        data: "portal_id",
      },
      {
        data: "status",
      },
    ],
  });

  initDataTable("autark-parallel-care-table", {
    columns: [
      {
        data: "supervisor",
        className: "nowrap",
      },
      {
        data: "document",
        className: "nowrap",
      },
      {
        data: "ssn",
      },
      {
        data: "portal_id",
      },
    ],
    order: [[1, "desc"]],
  });
}

initDataTableParallelCareOverview();

// #############################################################################
// DATA TABLE HOLIDAY OVERVIEW

function initDataTableHolidayOverview () {
  if (!$.fn.dataTable) {
    return;
  }
  initDataTable("holiday-table", {
    columns: [
      {
        data: "supervisor",
        className: "nowrap",
      },
      {
        data: "holiday_representative",
        className: "nowrap",
      },
      {
        data: "until",
        className: "nowrap",
      },
      {
        data: "act",
        className: "nowrap",
      },

      {
        data: "ssn",
      },
      {
        data: "portal_id",
      },

    ],
  });
}

initDataTableHolidayOverview();

// #############################################################################
// ACT HISTORY

function initDataTableActHistory () {
  if (!$.fn.dataTable) {
    return;
  }

  initDataTable("act-history-table", {
    columns: [
      {
        data: "date",
        className: "nowrap",
      },
      {
        data: "subject",
        className: "nowrap",
      },
      {
        data: "category",
        className: "nowrap",
      },
      {
        data: "employee",
        className: "nowrap",
      },

    ],
    order: [[0, "desc"]],
  });
}

initDataTableActHistory();


// #############################################################################
// COMPANY ACT HISTORY

function initDataTableCompanyActHistory () {
  if (!$.fn.dataTable) {
    return;
  }

  initDataTable("company-act-history-table", {
    columns: [
      {
        data: "date",
        className: "nowrap",
      },
      {
        data: "subject",
        className: "nowrap",
      },
      {
        data: "category",
        className: "nowrap",
      },
      {
        data: "employee",
        className: "nowrap",
      },

    ],
    order: [[0, "desc"]],
  });
}

initDataTableCompanyActHistory();


// #############################################################################
// SCHOOL ACT HISTORY

function initDataTableSchoolActHistory () {
  if (!$.fn.dataTable) {
    return;
  }

  initDataTable("school-act-history-table", {
    columns: [
      {
        data: "date",
        className: "nowrap",
      },
      {
        data: "subject",
        className: "nowrap",
      },
      {
        data: "category",
        className: "nowrap",
      },
      {
        data: "employee",
        className: "nowrap",
      },

    ],
    order: [[0, "desc"]],
  });
}

initDataTableSchoolActHistory();

// #############################################################################
// FOLDER HISTORY

function initDataTableFolderHistory () {
  if (!$.fn.dataTable) {
    return;
  }

  initDataTable("folder-history-table", {
    columns: [
      {
        data: "date",
        className: "nowrap",
      },
      {
        data: "subject",
        className: "nowrap",
      },
      {
        data: "category",
        className: "nowrap",
      },
      {
        data: "employee",
        className: "nowrap",
      },
      {
        data: "department",
        className: "nowrap",
      },
    ],
    order: [[0, "desc"]],
  });
}

initDataTableFolderHistory();


// #############################################################################
// PHASES

function initDataTablePhase () {
  if (!$.fn.dataTable) {
    return;
  }

  initDataTable("phase-table", {
    columns: [
      {
        data: "name",
        className: "nowrap",
      },
      {
        data: "begin",
        className: "nowrap",
      },
      {
        data: "end",
        className: "nowrap",
      },
      {
        data: "edit",
        className: "nowrap",
      },
      {
        data: "delete",
        className: "nowrap",
      },
    ],
    order: [[1, "desc"]],
    paging: false,
    bInfo: false,
  });
}

initDataTablePhase();

// #############################################################################
// FORMSET ADD STATUS BUTTONS

function initStatusFormsetButtons () {
  $(document).ready(function () {
    // when user clicks add more btn of formset
    $("#add-status-form-button").click(function (ev) {
      ev.preventDefault();
      const formsetForms = $("#status-formset-forms");
      const count = formsetForms.children().length;
      const tmplMarkup = $("#status-formset-template").html();
      const compiledTmpl = tmplMarkup.replace(/__prefix__/g, count);
      formsetForms.append(compiledTmpl);

      setStatusInitialBeginDate(formsetForms, count);

      const totalForms = $("#id_status-TOTAL_FORMS");
      totalForms.attr("value", count + 1);

      // when adding form and no line divider visible
      const lineDivider = $("#formset-line-divider");
      if (lineDivider.hasClass("hidden")) {
        lineDivider.removeClass("hidden");
      }
    });
  });
}

initStatusFormsetButtons();

function setStatusInitialBeginDate (formsetForms, count) {
  const newFormBegin = document.getElementById(`id_status-${count}-begin`);

  let previousEndDate = null;
  for (let id = count - 1; id >= 0; id--) {
    const isFormDeleted = document.getElementById(`id_status-${id}-DELETE`).checked;

    if (!isFormDeleted) {
      previousEndDate = document.getElementById(`id_status-${id}-end`).value;
      break;
    }
  }

  // set begin date of new status to next day of previous status or to phase begin if no (non-deleted) status exist
  // if previous status has no end date (-> empty string) do nothing
  if (previousEndDate) {
    const beginDate = new Date(previousEndDate);
    beginDate.setDate(beginDate.getDate() + 1);
    newFormBegin.value = beginDate.toISOString().split("T")[0];
  } else if (previousEndDate === null) {
    newFormBegin.value = document.getElementById("id_begin").value;
  }
}

// #############################################################################
// ATTACHMENTS

function initAttachmentsFilter () {
  const $attachments = $("#attachments");

  if (!$attachments.length) {
    return;
  }

  $body.on("change", "[data-attachments-filter]", function () {
    $.ajax({
      url: this.value,
      success: function (data) {
        $attachments.empty().html(data);
      },
    });
  });
}

function initAddAttachment () {
  $body.on("change", "[data-attachments-filter]", function () {
    const $option = $("option:selected", this);
    const href = $option.attr("data-attachment-add");

    $("[data-add-attachment]").attr("href", href);
  });
}

initAttachmentsFilter();
initAddAttachment();

// #############################################################################
// COPY ACT

function initCopyAct () {
  const $idArea0 = document.getElementById("id_area_0");

  if ($idArea0) {
    $idArea0.setAttribute("disabled", "disabled");
  }
}

initCopyAct();

// ##############################################################################
// TOOGLE CHECKBOX

$("#id_select_all_location").click(function () {
  $("[name=location]").prop("checked", this.checked);
});

$("#id_select_all_user").click(function () {
  $("[name=user_choice]").prop("checked", this.checked);
});

$("#id_all_acts_open").click(function () {
  $("[name=open_acts]").prop("checked", this.checked);
});

$("#id_all_acts_closed").click(function () {
  $("[name=closed_acts]").prop("checked", this.checked);
});

$("#id_all_acts_extended").click(function () {
  $("[name=extended_acts]").prop("checked", this.checked);
});

$("#id_all_acts_holiday_represented").click(function () {
  $("[name=holiday_represented_acts]").prop("checked", this.checked);
});

$("#id_all_acts").click(function () {
  $("[name=open_acts]").prop("checked", this.checked);
  $("[name=closed_acts]").prop("checked", this.checked);
  $("[name=extended_acts]").prop("checked", this.checked);
  $("[name=holiday_represented_acts]").prop("checked", this.checked);
});

// ##############################################################################
// REMOVE LOCALSTORAGE AT LOGOUT

$("#logout-button").click(function () {
  localStorage.removeItem("DataTables_DataTables_Table_0_/act/list/");
  localStorage.removeItem("DataTables_DataTables_Table_0_/parallel_care/overview/");
  localStorage.removeItem("DataTables_DataTables_Table_0_/holiday/overview/");
});

// #############################################################################
// CLIPBOARD

/**
 * Initial:
 *
 * <input id="target" value="Copy text">
 * <a data-clipboard="copy" data-clipboard-target="#target">Copy</a>
 *
 * or
 *
 * <a data-clipboard data-clipboard-text="Copy text">Copy</a>
 *
 * $("body").clipBoard({
 *  selector: "[data-clipboard]",
 *  beforeCopyText: function ($target, text) {
 *    // $target = jQuery object from [data-clipboard-target]
 *    // text = Text to be copied
 *    return text;
 *  },
 * });
 *
 **/

(function ($) {
  "use strict";

  const plugin_name = "clipBoard";

  const $defaults = {
    selector: "[data-clipboard]",
    beforeCopyText: function ($target, text) {
      return text;
    },
  };

  class Plugin {
    constructor ($element, $options) {
      this.$settings = $.extend({}, $defaults, $options);

      this.el = $element;

      this.$el = $($element);
      this.$dummy = undefined;

      this.init();
    }

    init () {
      const _this = this;

      _this.$el.on("click", _this.$settings.selector, function () {
        const $this = $(this);

        const text = $this.data("clipboard-text");
        const target = $this.data("clipboard-target");

        _this.$target = $(target);

        _this.action = $this.data("clipboard");

        if (text) {
          _this.action = "copy";

          _this._fakeSelectText(text);
        } else {
          _this._selectText();
        }

        _this._copyText();
        _this._clearSelection();

        return false;
      });
    }

    _fakeSelectText (select_text) {
      const _this = this;

      select_text = _this.$settings.beforeCopyText(
        _this.$target, select_text
      );

      _this.$dummy = $("<textarea>");
      _this.$dummy.val(select_text);

      _this.$el.append(_this.$dummy);

      _this.$dummy.get(0).select();
      _this.$dummy.get(0).setSelectionRange(0, select_text.length);

      return select_text;
    }

    _selectText () {
      const _this = this;
      let select_text;

      if (_this.$target.is("select")) {
        select_text = $("option:selected", _this.$target).text().trim();
        _this._fakeSelectText(select_text);
      } else if (_this.$target.is("input") || _this.$target.is("textarea")) {
        const is_read_only = _this.$target.attr("readonly");
        const select_text = _this.$target.val().trim();

        if (!is_read_only) {
          _this.$target.prop("readonly", true);
        }

        if (_this.action === "copy") {
          _this._fakeSelectText(select_text);
        } else {
          _this.$target.get(0).select();
          _this.$target.get(0).setSelectionRange(0, select_text.length);
        }

        if (!is_read_only) {
          _this.$target.removeAttr("readonly");
        }
      }
    }

    _clearSelection () {
      const _this = this;

      document.activeElement.blur();
      window.getSelection().removeAllRanges();

      _this.$el.focus();

      if (_this.$dummy) {
        _this.$dummy.remove();
      }
    }

    _copyText () {
      const _this = this;

      document.execCommand(_this.action);
    }
  }

  $.fn[plugin_name] = initPlugin(Plugin, plugin_name);
})(jQuery);

$body.clipBoard({
  selector: "[data-clipboard]",
  beforeCopyText: function ($target, text) {
    if ($target.attr("id") === "id_ssn") {
      text = text.replace(/\s/g, "");
    }

    return text;
  },
});

// #############################################################################
// LEARNING SUPPORT

function getTotalAmount ($pricePerUnit, $appliedUnits) {
  const $totalAmount = document.querySelector("#id_total_amount");
  const totalAmount = $pricePerUnit.value * $appliedUnits.value;

  if ($totalAmount) {
    $totalAmount.value = totalAmount;
  }
}

function LearningSupportCalcTotalAmount () {
  const $pricePerUnit = document.querySelector("#id_price_per_unit");
  const $appliedUnits = document.querySelector("#id_applied_units");

  if ($pricePerUnit && $appliedUnits) {
    $pricePerUnit.addEventListener("input", function () {
      getTotalAmount($pricePerUnit, $appliedUnits);
    });

    $appliedUnits.addEventListener("input", function () {
      getTotalAmount($pricePerUnit, $appliedUnits);
    });
  }
}

LearningSupportCalcTotalAmount();

// #############################################################################
// PRACTISE DURATION

function getDuration ($start, $end, $duration) {
  const $momentsDuration = moment.duration(moment($end.value) - moment($start.value));

  if (isNaN($momentsDuration) || $momentsDuration < 0) {
    $duration.value = gettext("Invalid Input");
  } else {
    $momentsDuration.add(1, "days");
    $duration.value = "" + $momentsDuration.days() + " " + gettext("Days") + ", " + $momentsDuration.months() + " " + gettext("Months") + ", " + $momentsDuration.years() + " " + gettext("Years");
  }
}

function Easter (Y) {
  const C = Math.floor(Y / 100);
  const N = Y - 19 * Math.floor(Y / 19);
  const K = Math.floor((C - 17) / 25);
  let I = C - Math.floor(C / 4) - Math.floor((C - K) / 3) + 19 * N + 15;
  I = I - 30 * Math.floor((I / 30));
  I = I - Math.floor(I / 28) * (1 - Math.floor(I / 28) * Math.floor(29 / (I + 1)) * Math.floor((21 - N) / 11));
  let J = Y + Math.floor(Y / 4) + I + 2 - C + Math.floor(C / 4);
  J = J - 7 * Math.floor(J / 7);
  const L = I - J;
  const M = 3 + Math.floor((L + 40) / 44);
  const D = L + 28 - 31 * Math.floor(M / 4);

  return new Date(Y, M - 1, D);
}

function getWorkdayDuration ($start, $end, $duration) {
  const $momentsDuration = moment.duration(moment($end.value) - moment($start.value));
  if (isNaN($momentsDuration) || $momentsDuration < 0) {
    $duration.value = gettext("Invalid Input");
  } else {
    const holidays = [];

    // for all relevant years, add easter-dependant-holidays and static holidays to the exception list
    for (let step = moment($start.value).year(); step <= moment($end.value).year(); step++) {
      // calc Easter
      const easter = moment(Easter(step));

      // calc Easter-dependant-holidays
      // holidays.push(easter.clone().add(-2, "d").format()); // Good Friday
      // holidays.push(easter.format()); // Easter Sunday is excluded anyways
      holidays.push(easter.clone().add(1, "d").format());
      holidays.push(easter.clone().add(39, "d").format());
      holidays.push(easter.clone().add(50, "days").format());
      holidays.push(easter.clone().add(60, "days").format());

      // calc static Holidays
      //                   year, month-1, day
      holidays.push(moment([step, 0, 1]).format());
      holidays.push(moment([step, 0, 6]).format());
      holidays.push(moment([step, 4, 1]).format());
      holidays.push(moment([step, 4, 4]).format());
      holidays.push(moment([step, 7, 15]).format());
      holidays.push(moment([step, 9, 26]).format());
      holidays.push(moment([step, 10, 1]).format());
      holidays.push(moment([step, 11, 8]).format());
      holidays.push(moment([step, 11, 24]).format()); // Christmas Eve
      holidays.push(moment([step, 11, 25]).format());
      holidays.push(moment([step, 11, 26]).format());
      holidays.push(moment([step, 11, 31]).format()); // New Years Eve
    }

    const $my_days = moment().weekdayCalc({
      rangeStart: $start.value,
      rangeEnd: $end.value,
      weekdays: [1, 2, 3, 4, 5],
      exclusions: holidays,
    });
    $duration.value = "" + $my_days + " " + gettext("Days");
  }
}

function PractiseDurationCalc () {
  const $start = document.querySelector("#id_practise_begin");
  const $end = document.querySelector("#id_practise_end");
  const $duration = document.querySelector("#id_practise_duration");

  if ($start && $end) {
    $start.addEventListener("input", function () {
      getDuration($start, $end, $duration);
    });

    $end.addEventListener("input", function () {
      getDuration($start, $end, $duration);
    });
  }
}

PractiseDurationCalc();

function TrialApprenticeshipDurationCalc () {
  const $start = document.querySelector("#id_trial_apprenticeship_date");
  const $end = document.querySelector("#id_trial_apprenticeship_end_date");
  const $duration = document.querySelector("#id_trial_apprenticeship_calc");

  if ($start && $end) {
    $start.addEventListener("input", function () {
      getWorkdayDuration($start, $end, $duration);
    });

    $end.addEventListener("input", function () {
      getWorkdayDuration($start, $end, $duration);
    });
  }
}

TrialApprenticeshipDurationCalc();

// #############################################################################
// PRACTISE COMPANY LIST

function PractiseCompanyList () {
  const $practiseCompany = document.querySelector("[data-info-url]");

  if ($practiseCompany) {
    $practiseCompany.addEventListener("input", function () {
      const $datalist = this.list;
      const practisePrefix = this.dataset.infoPrefix;
      const $option = $datalist.querySelector('[value="' + this.value + '"]'); // eslint-disable-line
      const companyInfoUrl = this.dataset.infoUrl;

      if ($option) {
        $x.ajax.get(companyInfoUrl + "?id=" + $option.dataset.value, {
          dataType: "json",
          success: ($data) => {
            for (const [key, value] of Object.entries($data[0].fields)) {
              let id = "id_" + key;

              if (practisePrefix) {
                id = "id_" + practisePrefix + "_" + key;
              }

              const $input = document.getElementById(id);

              if ($input) {
                $input.value = value;
              }
            }
          },
        });
        document.querySelector("#id_companyID").value = $option.dataset.value;
      } else {
        if (!practisePrefix) {
          const $inputs = ["addition", "street", "house_number", "zip_code", "place", "email", "phone_number"];

          for (const n in $inputs) {
            const $companyField1 = document.querySelector("input[id=\"id_" + $inputs[n] + "\"]");

            if ($companyField1) {
              $companyField1.value = "";
            }

            const $companyField2 = document.querySelector("input[id=\"id_company_" + $inputs[n] + "\"]");

            if ($companyField2) {
              $companyField2.value = "";
            }
          }
        }
      }
    });
  }
}

PractiseCompanyList();


// #############################################################################
// URL PARAMS TO FORM

function urlParamsToForm () {
  const $queryString = window.location.search;
  const $urlParams = new URLSearchParams($queryString);

  for (const entry of $urlParams.entries()) {
    const $input = document.getElementById("id_" + entry[0]);

    if ($input) {
      $input.value = entry[1];
    }
  }
}

urlParamsToForm();


// #############################################################################
// UPDATE ADD COMPANY URL

function updateAddCompanyUrl () {
  const $addCompany = document.getElementById("add-company");

  if ($addCompany) {
    const $input = $addCompany.closest(".field").querySelector("input");

    $input.addEventListener("input", function () {
      let href = $addCompany.href.split("?")[0];
      href += "?company=" + this.value;
      $addCompany.href = href;
    });
  }
}

updateAddCompanyUrl();


// #############################################################################
// AFIT PHASES HISTORY

function initDataTableAfitPhasesHistory () {
  if (!$.fn.dataTable) {
    return;
  }

  initDataTable("afit-phases-history-table", {
    columns: [
      {
        data: "date",
        className: "nowrap",
      },
      {
        data: "afit_phase",
        className: "nowrap",
      },
    ],
    order: [[0, "desc"]],
  });
}

initDataTableAfitPhasesHistory();


// #############################################################################
// DELETE HOLIDAY REPRESENTATIVE

function initDeleteHoliday () {
  const $deleteAllActs = document.querySelector("#id_delete_all_acts");

  if ($deleteAllActs) {
    $deleteAllActs.addEventListener("input", function () {
      const $deleteLink = document.querySelector("[data-delete-holiday-representative]");
      const href = $deleteLink.href.split("?")[0];
      const $url = new URL(href);
      const $params = new URLSearchParams($url.search);

      $params.set("all_acts", this.checked);
      $deleteLink.href = href + "?" + $params.toString();
    });
  }
}

initDeleteHoliday();

// #############################################################################
// DISABLE CARE RESULT AND SET VALUE TO NON RELEVANT

function changeCareResulOnCareGoalChange () {
  if (document.getElementById("id_care_goal")) {
    const $idCareGoal = document.getElementById("id_care_goal");
    const old_value = $idCareGoal.value;

    if ($idCareGoal) {
      $idCareGoal.addEventListener("input", function () {
        // change value when care goal is set to new value
        disableCareResult();
        if ($idCareGoal) {
          // set value to null if care goal 3 gets changed to 1 or 2
          if (old_value === "3" && $idCareGoal.value !== "3") {
            const $idCareResult = document.getElementById("id_care_result");
            $idCareResult.value = "";
          }
        }
      });
    }
  }
}

function disableCareResult () {
  const $idCareGoal = document.getElementById("id_care_goal");
  if ($idCareGoal) {
    if ($idCareGoal.value === "3") {
      const $idCareResult = document.getElementById("id_care_result");
      $idCareResult.value = "401_1";
      $idCareResult.disabled = true;
    } else {
      const $idCareResult = document.getElementById("id_care_result");
      $idCareResult.disabled = false;
    }
  }
}


if ($("[data-is-miteinander]").length > 0) {
  changeCareResulOnCareGoalChange();
  // disable care result and set value when page is loaded
  disableCareResult();
}

// #############################################################################
// DATATABLE FILTER HANDLING

function setCustomFilter (button) {
  const $table = $("table").DataTable();

  $table.search("fullname__startswith", { value: button.value }).draw();
  button.classList.add("custom-filter-active");
}

function resetCustomFilters () {
  const $table = $("table").DataTable();
  const buttons = document.querySelectorAll(".custom-filters");

  $table.search("").draw();
  buttons.forEach(el => {
    el.classList.remove("custom-filter-active");
  });
}

$body.on("click", ".custom-filters", function (el) {
  resetCustomFilters();
  setCustomFilter(el.currentTarget);
});

$body.on("click", "#reset_custom_filters", function () {
  resetCustomFilters();
});


$body.on("click", "#id_reset_button", function () {
  updateSupervisorList();
  const $inputs = document.querySelectorAll("[data-column]");
  $inputs.forEach(function ($input) {
    $input.value = "";
  });
  const $table = $("table").DataTable();
  $table.columns().search("").draw();
  resetCustomFilters();
});

$body.on("click", "#id_filter_collapse_button", function () {
  const $filter_collapse_card = document.getElementById("filter_collapse_card");
  if ($filter_collapse_card.classList.contains("hidden")) {
    $filter_collapse_card.classList.remove("hidden");
  } else {
    $filter_collapse_card.classList.add("hidden");
  }
});


// #############################################################################
// ACT HISTORY

function initVerifyAct () {
  const $checkbox_verify_act = document.getElementById("verify_act");
  $body.on("change", "#verify_act", async function () {
    await fetch($checkbox_verify_act.getAttribute("data-url"), {
      method: "POST",
      headers: {
        "X-CSRFToken": getCSRFToken(),
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: "verified=" + $checkbox_verify_act.checked,
    });
  });
}

initVerifyAct();
